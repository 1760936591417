@tailwind base;
@tailwind components;
@tailwind utilities;

/* color vars */
:root{
  --custom-blue: #219FDD;
  --custom-grey: #111827;
}

ol li {
  list-style: decimal;
  list-style-position: inside;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-btn-div{
  margin: 0 auto;
}

.data{
  margin-left: auto;
  font-weight: bold;
}

/* .go-to-btn-div{
  z-index: 9;
  font-size: 50px;
  color: red;
} */

.headline-div-en{
  margin: 0 9% 0 0%;
}

.headline-div{
  margin: 0 0% 0 6%;
}

.bg-sectino1 {
  /* background: #3b065e; */
  background: #111827;
  /* background: #1391CF; */
}

.text-blue {
  color: #1391CF;
}

.bg-header{
  /* background: #1391CF; */
    /* background: #111827; */
  /* background: #3c0661; */
  /* max-height: 212px; */
  background: url('./assets/WhiteGreySlim2.jpg');
  background-size: cover;
  background-repeat:no-repeat;
  background-position-x: 20px;
}
.header-container-div{
  padding: 0 5% 0% 1%;
}
.image-div{
  min-width: 300px;
  min-height: 200px;
}

.portfolio-container-div{
  padding: 1%;
}

.container-div {
  padding: 1% 5% 1% 5%;
}
.main-heading {
  font-size: 40px;
  font-weight: 900;
}

.first-box {
  top: 50px;
  left: 0;
}
.secong-box {
  top: 100px;
  left: 400px;
}
.shadow-header {
  box-shadow: -2px 4px 15px 1px rgba(215,230,230,1);
  -webkit-box-shadow: -2px 4px 15px 1px rgba(215,230,230,1);
  -moz-box-shadow: -2px 4px 15px 1px rgba(215,230,230,1);
}

.fade {
  animation: animate;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}
.animate-box {
  animation: topdown;
  animation-duration: 2s;
  /* animation-delay: 2s; */
  animation-timing-function: ease-in-out;
}


.form-container-div{
  margin: 0 auto;
  max-width: 800px;
}

@keyframes topdown {
  0% {top: -10;}
  25% {top: 0;}
  50%{top: 10;}
  75%{top: 20;}
  100%{top: 30;}
}
@keyframes animate {
  0% {
    opacity: 0;
    margin-top: 0;
  }
  50% {
    opacity: 0.5;
    margin-top: -10;
  }
  100% {
    opacity: 1;
    margin-top: -20;
  }
}

@media only screen and (max-width: 1700px) {
  .bg-header {
    background: url('./assets/WhiteGreySlim3.jpg');
    background-size: cover;
    background-repeat:no-repeat;
    background-position-x: 20px;
  }
}

@media only screen and (max-width: 1500px) {
  .bg-header {
    background: var(--custom-grey);
  }
  .headline-div{
    margin: 0 0 0 0%;
  }
}

@media only screen and (max-width: 1164px) {
  .headline-div{
    margin: 15px 0 0 0%;
  }
}

@media only screen and (max-width: 730px) {
  .headline-div{
    display: none;
  }
  .image-div{
    margin:auto;
  }
}
