.noButtons {
  visibility: hidden;
  display: none;
}

.multiStepContainer {
  margin-bottom: -60px;
}

.subscript{
  display: inline;
  vertical-align: super;
}
