:root{
  --custom-blue: #219FDD; 
  --custom-grey: #111827;
}

.infobox{
    /* margin: auto;
    padding: 10px; */
    text-align: left;
    width: 90%;
    border: 3px dashed white;
  }
  
  .autocomplete{
    margin: 10px;
    margin-top: 20px;
    display: inline-block;
  }
  .textbox{
    border: 2px dashed white;
    border-radius: 2px;
  }
  .button{
    color:#111827;
    background-color: white;
    border: 2px dashed white;
    border-radius: 2px;
  }
  
  .shareInterfaceOuterContainer{
    margin-top: 30px;
  }

  .custommargin{
    margin: 10px;
    margin-top: 20px;
    display: inline-block;
  }

  .heading{
    /* color: red; */
    /* font-family: 'Roboto'; */
    font-weight: 600;
    font-size: larger;
    color: var(--custom-blue);
    line-height: 1.5;
    margin: 0px;
    margin-top: 32px;
  
    /* @media screen and (min-width: 768px) {
      font-weight: 500;
      font-size: 36px;
      line-height: 1.22;
    } */
  }
  
  .description{
    /* color: #64748b;
    font-family: 'Roboto'; */
    font-size: 16px;
    line-height: 1.75;
    margin-top: 16px;
  
    /* @media screen and (max-width: 768px) {
      text-align: center;
      font-size: 12px;
      line-height: 1.33;
      width: 241px;
      margin-top: 6px;
    } */
  }