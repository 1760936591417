.button {
  color: white;
  background: -webkit-linear-gradient(left, #219FDD, #753d16);
  background-size: 200% 200%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  border-width: 1px;
  padding: 13px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2em;
}
.button:hover {
  background: -webkit-linear-gradient(left, #219FDD, #219FDD);
}

.address {
  background-color: #219FDD;
  color: black;
  padding: 5px;
  border-radius: 5px;
  border: none;
}



