.metamaskText{
    text-align: center;
}

.goerliText{
    text-align: center;
}

.contractText{
    text-align: center;
}

.infoheading{
    margin-left: 20px;
    margin-right: 20px;
    font-weight: bold;
}