.contractInterface {
  padding-top: 20px;
  /* padding-bottom: 20px; */
  background: #111827;
  color: white;
  padding-bottom: 20px;
}

.connectToMetamaskContainer {
  margin:auto;
  padding-top: 20px;
  /* padding-bottom: 20px; */
  background: #111827;
  color: white;
}

.getEtherContainer {
  padding-top: 20px;
  /* padding-bottom: 20px; */
  background: #111827;
  color: white;
}
